<template>
  <user-form :initial-user="user" :title="title" :editing="false" />
</template>

<script>
import UserForm from "./UserForm.vue";

export default {
  name: "AddUser",

  components: {
    UserForm,
  },
  data() {
    return {
      title: "Tambah User",
      user: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
      },
    };
  },
};
</script>
