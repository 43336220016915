<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
    <h1 class="heading-5 mt-5">{{ title }}</h1>

    <v-text-field
      v-model="user.firstName"
      :rules="nameRules"
      label="First Name"
      required
      prepend-icon="mdi-account"
    ></v-text-field>

    <v-text-field
      v-model="user.lastName"
      :rules="nameRules"
      label="Last Name"
      required
      prepend-icon="mdi-account"
    ></v-text-field>

    <v-text-field
      v-model="user.email"
      :rules="emailRules"
      label="E-mail"
      required
      prepend-icon="mdi-email"
    ></v-text-field>

    <v-text-field
      v-model="user.username"
      :rules="usernameRules"
      label="Username"
      autocomplete="false"
      aria-autocomplete="false"
      required
      prepend-icon="mdi-account"
    ></v-text-field>
    <v-text-field
      v-model="user.password"
      :rules="passwordRules"
      type="password"
      label="Password"
      autocomplete="false"
      aria-autocomplete="false"
      required
      prepend-icon="mdi-account"
    ></v-text-field>

    <v-text-field
      v-model="passwordConfirmation"
      :rules="passwordConfirmationRules"
      label="Password Confirmation"
      type="password"
      required
      prepend-icon="mdi-account"
    ></v-text-field>

    <v-btn
      :loading="loading"
      :disabled="!valid"
      color="primary"
      class="mr-4"
      @click="submitForm"
    >
      Submit
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "UserForm",
  props: {
    initialUser: {
      type: Object,
      default: Object,
    },
    title: {
      type: String,
    },
    editing: {
      type: Boolean,
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      user: this.initialUser,
      nameRules: [(v) => !!v || "Name is required"],
      usernameRules: [(v) => !!v || "Username is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      passwordConfirmationRules: [
        (v) => !!v || "Password Confirmation is required",
        (v) => v === this.user.password || "Password Confirmation didn't match",
      ],
      passwordConfirmation: "",
      currentUser: JSON.parse(localStorage.getItem("current_user")),
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        if (!this.editing) {
          this.user.userIdCreate = this.currentUser.id;
          this.user.userIdUpdate = this.currentUser.id;
          (this.user.userNameCreate = `${this.currentUser.firstName} ${this.currentUser.lastName}`),
            (this.user.userNameUpdate = `${this.currentUser.firstName} ${this.currentUser.lastName}`),
            this.$store.dispatch("addUser", this.user).then(() => {
              this.$router.push("/user_management");
            });
        } else {
          this.user.userIdUpdate = this.currentUser.id;
          (this.user.userNameUpdate = `${this.currentUser.firstName} ${this.currentUser.lastName}`),
            this.$store
              .dispatch("editUser", { id: this.user.id, dataUser: this.user })
              .then(() => {
                this.$router.push("/user_management");
              });
        }
      }
    },
  },
};
</script>

<style scoped></style>
